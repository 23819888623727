import {required} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                parentSelectCategory:null,
                typeSelectCategory: {
                        title:'Не вказано',
                        id:0
                    },

                default_width:0,
                default_height:0,
                default_length:0,
                default_weight:0,
                base_price:0,
            },
            typeCategoryList:[
                {
                    title:'Не вказано',
                    id:0
                },
                {
                    title:'Батьківська',
                    id:1
                },
                {
                    title:'Подчиняется',
                    id:2
                },
            ]
        }
    },

    validations: {
        form: {
            title: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            productCategory:'catalog/productCategory',
            productCategoryList:'catalog/productCategoryList',
        }),
    },
    watch:{
        productCategory(e){
            if(e){
                this.form.title = e.title
                this.form.default_width = e.default_width
                this.form.default_height = e.default_height
                this.form.default_length = e.default_length
                this.form.default_weight = e.default_weight
                this.form.base_price = e.base_price ? Number(e.base_price).toFixed(0) : 0
                if(e.parent_id){
                    this.form.parentSelectCategory = this.productCategoryList.filter(item => item.id === e.parent_id);
                    this.form.parentSelectCategory = this.form.parentSelectCategory[0];
                    this.form.typeSelectCategory = {
                        title:'Подчиняется',
                        id:2
                    }
                }else if(e.children.length){
                    this.form.typeSelectCategory = {
                        title:'Батьківська',
                        id:1
                    }
                }
            }else{
                this.form = {
                    title: '',
                }
            }
        }
    },
    methods: {
        changeTypeCategory(){
            if(this.form.typeSelectCategory.id === 0 || this.form.typeSelectCategory.id === 1){
                this.form.parentSelectCategory = null;
            }
        },
        closePopup() {
            this.$emit('closePopup');
        },
        sendProductCategory() {
            if(this.productCategory){
                this.form.id = this.productCategory.id;
                this.$emit('changeProductCategory', this.form)
            }else{
                this.$emit('sendProductCategory', this.form)
            }
        },
        ...mapMutations({
            changeProductCategory: 'catalog/changeProductCategory'
        }),
    },
    destroyed() {
        this.changeProductCategory([null]);
    }
}
